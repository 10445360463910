import React, { useContext } from "react";
import PropTypes from "prop-types";
import calcVehicleRangeForBudget from "../../functions/vehicle/calcVehicleRangeForBudget";
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import ShowHideButton from "../ShowHideButton/ShowHideButton";
import { FormatAsCents, FormatCarName } from "../../utils/Helpers/Format";
import { FormattedMessage, useIntl } from "react-intl"

import { Bar as HorizontalBar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import "../../utils/chartSetup";

const FUEL_BUDGET_IN_DOLLARS = 5;

const FuelRangeChart = ({ car, forceUserPrefsPresets, hasExtraPadding }) => {

  const intl = useIntl;
  const userPrefs = useContext(UserPrefsContext);

  if (!car) return null;
  const equivalentGasVehicle = car.equivalent_gas_vehicle || {};

  const get = forceUserPrefsPresets ? userPrefs.getPreset : userPrefs.get;

  const vehicleRange = Math.round(
    calcVehicleRangeForBudget(
      car,
      FUEL_BUDGET_IN_DOLLARS,
      get("electricMilesPortionForPhev")
    )
  );
  const equivalentGasVehicleRange = Math.round(
    calcVehicleRangeForBudget(equivalentGasVehicle, FUEL_BUDGET_IN_DOLLARS)
  );

  const title = (
    <p className="h3 my-3">
      <FormattedMessage
        id="graph.fuelRange.chart.howFar"
        defaultMessage="How far can you drive the {car} on a {budget} fuel budget?"
        description="How far question"
        values={{
          car: FormatCarName(car),
          budget: <strong style={{ fontWeight: "800" }}>
            {FormatAsCents(FUEL_BUDGET_IN_DOLLARS)}
          </strong>
        }}
      />
    </p>
  );

  const carNames = [
    [FormatCarName(car), "on Plug-In EVerywhere network"],
    [FormatCarName(car), "charged at home"],
    ["Similar Gas Vehicle", FormatCarName(car.equivalent_gas_vehicle)]
  ];

  const chartData = {
    labels: carNames,
    datasets: [
      {
        backgroundColor: ["#f15c2a", "#5ca4d1", "#708090"],
        data: [
          Math.round(vehicleRange * 1.5),
          vehicleRange,
          equivalentGasVehicleRange
        ]
      }
    ]
  };

  const chartOptions = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    tooltips: {
      enabled: false
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          ticks: {
            max: Math.round((vehicleRange * 1.7) / 20) * 20,
            stepSize: 20
          }
        }
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            font: {
              weight: 700
            }
          }
        }
      ]
    },
    legend: {
      display: false
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          if (ctx.dataIndex === 0) {
            return "Unlimited";
          }
          return value + " " + process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "vehicle.kilometers", defaultMessage: "kilometers" }) : intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles" });
        },
        align: "end",
        anchor: "end",
        color: "#333333",
        font: {
          weight: 700
        }
      }
    }
  };

  return (
    <div
      className="input-well text-center"
      style={hasExtraPadding ? { padding: "2.5rem" } : { paddfing: "1rem" }}
    >
      {title}
      <div className="d-none d-lg-block d-xl-block">
        <div>
          <HorizontalBar
            data={chartData}
            height={260}
            type="horizontalBar"
            options={chartOptions}
          />
        </div>
      </div>
      {hasExtraPadding && <br />}
      <ShowHideButton buttonText="Graph Values" displayChildrenOnlyOnTablet>
        <br />
        <div className="row">
          <div className="col-sm-12">
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                  <th scope="col">
                    <FormattedMessage
                      id="graph.fuelRange.chart.description"
                      defaultMessage="Description"
                      description="Description Title"
                    />
                  </th>
                  <th scope="col">
                    <FormattedMessage
                      id="graph.fuelRange.chart.rangeOn"
                      defaultMessage="Range on"
                      description="Range on"
                    /> {FormatAsCents(FUEL_BUDGET_IN_DOLLARS)} 
                    <FormattedMessage
                      id="graph.fuelRange.chart.fuelBudget"
                      defaultMessage="fuel budget"
                      description="fuel budget"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    {FormatCarName(car)} 
                    <FormattedMessage
                      id="graph.fuelRange.chart.network"
                      defaultMessage="on Plug-In EVerywhere network"
                      description="on Plug-In EVerywhere network"
                    />
                  </th>
                  <td>
                    <FormattedMessage
                      id="graph.fuelRange.chart.unlimited"
                      defaultMessage="Unlimited"
                      description="Unlimited"
                    />
                  </td>
                </tr>
                <tr>
                  <th scope="row">{FormatCarName(car)}
                    <FormattedMessage
                      id="graph.fuelRange.chart.chargedHome"
                      defaultMessage="charged at home"
                      description="charged at home"
                    />
                  </th>

                  <td>{vehicleRange} 
                  {process.env.REACT_APP_METRIC_SYSTEM ? 
                      <FormattedMessage
                        id="graph.fuelRange.chart.kilometers"
                        defaultMessage="kilometers"
                        description="kilometers"
                      /> :
                      <FormattedMessage
                        id="graph.fuelRange.chart.miles"
                        defaultMessage="miles"
                        description="miles"
                      />}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    {FormatCarName(car.equivalent_gas_vehicle)} (<FormattedMessage
                      id="graph.fuelRange.chart.similarGas"
                      defaultMessage="a similar Gas Vehicle"
                      description="a similar Gas Vehicle"
                    />)
                  </th>
                  <td>{equivalentGasVehicleRange} 
                    {process.env.REACT_APP_METRIC_SYSTEM ?
                      <FormattedMessage
                        id="graph.fuelRange.chart.kilometers"
                        defaultMessage="kilometers"
                        description="kilometers"
                      /> :
                      <FormattedMessage
                        id="graph.fuelRange.chart.miles"
                        defaultMessage="miles"
                        description="miles"
                      />}
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ShowHideButton>
    </div>
  );
};

export default FuelRangeChart;

FuelRangeChart.propTypes = {
  car: PropTypes.object,
  forceUserPrefsPresets: PropTypes.bool,
  hasExtraPadding: PropTypes.bool
};
